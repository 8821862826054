import { MailMessage } from '../types/mail.types';
import { SiteInfo } from '../types/site.types';

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3080' : '';

async function get<RESPONSE>(path: string): Promise<RESPONSE> {
  return fetch(`${apiUrl}${path}`).then(res => {
    return res.json() as unknown as RESPONSE;
  });
}

export default class Api {
  static getMail = (id: string): Promise<MailMessage> => get(`/api/mail/${id}`);
  static getSiteInfo = (): Promise<SiteInfo> => get('/api/data');
}
