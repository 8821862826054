import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import styles from './MailRoomInfoBar.module.scss';

interface MailRoomInfoBarProps {
  inbox?: string;
  onClear?: () => void;
  onLeave?: () => void;
}

export default function MailRoomInfoBar({ inbox, onClear, onLeave }: MailRoomInfoBarProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div>{`${t('ReceivingEmailsFrom')}: ${inbox}`}</div>
      <Button size={'sm'} onClick={onClear}>{t('Clear')}</Button>
      <Button size={'sm'} onClick={onLeave}>{t('Leave')}</Button>
    </div>
  );
}
