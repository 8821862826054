export default {
  EnterReelRoomName: 'Voer de reel naam in',
  ReelName: 'Reel naam',
  From: 'Van',
  To: 'Aan',
  Subject: 'Onderwerp',
  Received: 'Ontvangen',


  Login: 'Inloggen',
  Username: 'Gebruikersnaam',
  Password: 'Wachtwoord',
  Welcome: 'Welkom',
  IDontHaveAnAccount: 'Ik heb nog geen account',
  IAlreadyHaveAnAccount: 'Ik heb al een account',
  EmailAddress: 'Email adres',
  ChangePassword: 'Wachtwoord wijzigen',
  CollapseSidebar: 'Collapse sidebar',
  ExtendSidebar: 'Expand sidebar',
  Home: 'Home',
};
