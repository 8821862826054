export default {
  // Important! Required site info:
  AppTitle: 'Recevi',
  SiteTagLine: 'Create custom inboxes to test email workflows',
  // Meta information. This is the text shown in search snippets and used by search engines to index a page
  SiteMetaDescription: 'Devidex lists projects to share with others. Share them and vote for them to reach more people and grow your community',
  LoginMetaDescription: 'Login on Devidex with your credentials and join the many with sharing projects',
  LogoutMetaDescription: 'Logout of Devidex. Hope to see you soon again',
  PrivacyPolicyMetaDescription: '',
  RegisterMetaDescription: '',
  TermsOfServiceMetaDescription: '',
  ResetPasswordMetaDescription: '',
  VerificationMetaDescription: 'Verify your account',
  StatisticsMetaDescription: 'Web traffic and statistics to give an indication of the activities on this website',

  // Terms used on the website
  InboxName: 'Inbox name',
  From: 'From',
  To: 'To',
  Subject: 'Subject',
  Received: 'Received',
  SendEmail: 'Send email',
  SendEmailDescription: 'Send an email to @recevi.com with a prefix of your chosen name. For specific emails separete it with an ".". For example: "yourRoomName@mailreel.com" or "john.yourRoomName@mailreel.com',
  EmailsImported: 'Emails Imported',
  StepCreateInbox: 'Create a room',
  StepCreateInboxDescription: 'Open a room by entering a username and the room naam',
  StepSendEmail: 'Send emails',
  StepSendEmailDescription: 'Send emails to @recevi.com prefixed by your room name.',
  StepReceiveEmails: 'Receive emails',
  StepReceiveEmailsDescription: 'Receive and read your test emails right in your browser. After 10 minutes they will be completely removed.',
  SendAnEmailTo: 'Send emails to',
  AppDescriptionHome: `Recevi mail service lets users receive emails to test their applications.<br/>
    Simply create unlimited amount of public and free disposable inboxes and sent emails to test your email workflow.<br/>
    Emails are imported and shown directly in the browser.<br/><br/>
    This service allows developers, QA and testing teams to validate the working of your application with minimal effort.`,
  OpenInbox: 'Open inbox',

  Login: 'Login',
  Username: 'Username',
  Password: 'Password',
  UsernameIsRequired: 'Username is required',
  PasswordIsRequired: 'Password is required',
  EnterPassword: 'Enter password',
  EnterUsername: 'Enter username',
  Welcome: 'Welcome',
  IDontHaveAnAccount: 'I don\'t have an account yet',
  IAlreadyHaveAnAccount: 'I already have an account',
  EmailAddress: 'Email address',
  ChangePassword: 'Change password',
  RequestChangePassword: 'Request password change',
  PasswordChangeRequestEmailSend: 'An email with instructions to change your password has been send',
  Verified: 'Verified',
  Unverified: 'Unverified',
  SendVerifyEmail: 'Send verification email',
  VerificationMailSend: 'A verification mail has been send',
  Email: 'Email',
  Emails: 'Emails',
  EnterEmailAddress: 'Enter email address',
  Address: 'Address',
  NoEmailAddressSet: 'No address set',
  NoEmailRegistered: 'No address registered',
  RegisterAnAccount: 'Register an account',
  ResetPassword: 'Reset password',
  Registration: 'Registration',
  NoUserFound: 'No user found',
  Profile: 'Profile',
  DeleteAccount: 'Delete Account',
  DeleteUser: 'Delete user',
  DeleteAccountAndItsContent: 'Delete account and it\'s related data',
  NotAllowedToDeleteVerifiedAccount: 'You do not have permission to delete a verified account',
  UsernameThatIsDisplayed: 'Username that is used to login and displayed',
  RegisteredEmailAddress: 'Registered email address',
  EditUser: 'Edit user',
  EditUserDescription: 'Update the information related to an user or request verifications',
  Role: 'Role',
  Users: 'Users',
  Home: 'Home',
  ToggleMenuButton: 'Toggle Menu',
  SetYourNewPasswordAndSubmit: 'Set your new password',
  Security: 'Security',
  PasswordForgotten: 'Password Forgotten',
  PasswordForgottenRequestEmailText: 'An email with instructions will be send to this email address if verified',
  PasswordResetRequestText: 'An email with reset instructions will be send to you email address if it is verified',
  PasswordsDontMatch: 'Passwords don\'t match',
  IForgotMyPassword: 'I forgot my password',
  ToggleEditMode: 'Toggle edit',
  Update: 'Update',
  UpdatedEmail: 'Updated email',
  Account: 'Account',
  Submit: 'Submit',
  AccountDeleted: 'Account deleted',
  EncounteredAnErrorWithMessage: 'Encountered an error:',
  RoleUpdated: 'Role updated',
  Menu: 'Menu',
  AddNewUser: 'Add New User',
  NoEmailWarningTextOnRegister: 'We will never share your email with anyone else, but entering your email address is optional. If you decide to not provide your email address you will not be able to recover your account once you have lost your password.',
  VerifyEmail: 'Verify Email',
  VerifyAccount: 'Verify Account',
  EmailVerification: 'Email Verification',
  End: 'End',
  Projects: 'Projects',
  Favorites: 'Favorites',
  NewProject: 'New Project',
  ProjectTitle: 'Title',
  Description: 'Description',
  Tagline: 'Tagline',
  ProjectDescription: 'Description',
  ProjectTagline: 'Tagline',
  ServerInformation: 'Server Information',
  IPAddress: 'IP-Address',
  ConnectionPort: 'Connection Port',
  QueryPort: 'Query Port',
  ShowPortOnIpAddress: 'Show Port on IP Address',
  Votifier: 'Votifier',
  VotifierInfoDescription: 'Votifier Information',
  VotifierPublicKey: 'Public Key',
  VotifierIp: 'Votifier IP-Address',
  VotifierPort: 'Votifier Port',
  EditProject: 'Edit Project',
  ProjectsBy: 'Projects By',
  NoProjectsFound: 'No projects found',
  ServerInfoIncomplete: 'Server information is incomplete',
  ServerRequiredForVotifier: 'Server settings are required when using Votifier',
  ProjectMissing: 'Project missing',
  OnUpdate: 'on update',
  ServerIp: 'Server IP',
  Copy: 'copy',
  Online: 'Online',
  Tags: 'Tags',
  AddNewTag: 'Add new tag',
  AddTagsToDefineYourProject: 'Add tags that define your project',
  Host: 'Host',
  VotifierTokenOrPublicKeyIsRequired: 'Votifier token or public key is required',
  VotifierToken: 'Token',
  VotifierRecommandedToken: 'Use of the votifier token is recommanded over the public key',
  HostLocation: 'Host location',
  RecentVotes: 'Recent votes',
  AllTimeVotes: 'All time votes',
  TotalVotes: 'Total votes',
  PlayersOnline: 'Online players',
  PlayersMax: 'Max players',
  MOTD: 'Motd',
  Version: 'Version',
  ServerStatus: 'Server status',
  NotRegistered: 'Not registered',
  BecomePremium: 'Become Premium',
  WhatIsPremium: 'What is premium',
  WhatIsPremiumText: 'Premium is a service that can be bought to show a project on the top of listings on different pages. The pages where premium projects are shown are the home page and the tag pages if a project has the tag.',
  BecomePremiumStandard: 'Once logged in and with a project on your account on this page you will be able to select a one of your projects and generate a payment. After succesfull payment the project will get the premium status.',
  NoCurrentPremiumProjects: 'No premium projects are registered',
  YourPremiumProjects: 'Your premium projects',
  StartDate: 'Start date',
  EndDate: 'End date',
  NoProjectAvailableForPremium: 'No projects available to register as premium',
  BuyPremium: 'Buy Premium',
  FinalizingPayment: 'Finalizing payment',
  FinalizingPaymentText: 'Please wait while we are finalizing your payment. After the payment is completed you will be redirected to the premium page.',
  CancelingPayment: 'Finalize cancellation of payment',
  CancelingPaymentText: 'Please wait while we cancel your payment',
  DeleteAccountInfo: 'On deleting your account all your data will be removed. There is no way of returning the information.',
  ConfirmDeleteAccount: 'Confirm account deletion',
  DeleteConfirm: 'Confirm account deletion',
  RequestNewPasswordByUserIdText: 'An email will be send to the email address known to us. Follow the instructions to set a new password',
  RequestResetLink: 'Request reset link',
  NoVerifiedEmailAvailable: 'No verified email registered',
  NoPaymentsFound: 'No payments found',
  AddFavoritesToShowInThisList: 'Add favorites to show in this list',
  AddedAsFavorite: 'Added as favorite',
  RemovedAsFavorite: 'Removed as favorite',
  Id: 'Id',
  CreatedAt: 'Created Date',
  DeleteProjectText: 'By deleting the project all project information and all its related content will be deleted.',
  UserProjectsPageDesc: 'All projects created by the user',
  AddProject: 'Add project',
  UnableToDeletePremium: 'Unable to delete pending premium',
  UnableToGeneratePaymentOptions: 'Unable to generate payment options',
  SelectPaymentType: 'Select payment method',
  SelectPaymentTypeDesc: 'You will be redirected to the selected service to complete your payment',
  UserId: 'User id',
  ProjectId: 'Project id',
  PayedOn: 'Payed on:',
  PremiumStart: 'Premium start date',
  PremiumEnd: 'Premium end date',
  PaymentState: 'Payment state',
  CreateNewProject: 'Create new project',
  CreateNewProjectDesc: 'Create a new project with extended information to tell visitors about it and letting them know how to find and/or join you',
  CopyRightStatement: 'Copyright © 2023 Devidex . All rights reserved.',
  PrivacyPolicy: 'Privacy Policy',
  TermsOfService: 'Terms of Service',
  ServerUnreachable: 'Server Unreachable',
  ServerUnreachableDesc: 'The server has been unreachable to check its status. Please ensure the server is running and the given configuration is correct',
  PingServer: 'Ping Server',
  SuccessfullyPingedServer: 'Successfully pinged the server',
  ReloadPageForChanges: 'Reload the page to see the changes',
  ProjectVideoUrlDesc: 'Project video or trailer',
  ProjectVideo: 'Project video',
  DeleteBanner: 'Delete banner',
  ContactEmailInfo: 'Set an email address to enable a contact form.',
  ContactEmailShowInfo: 'By enabling this the email address will be shown on the contact page. This makes it possible for web scrapers to find it for spam.',
  ContactShowEmailAddress: 'Show email address on page',
  ContactPageText: 'To stay up to date with what is going on or for any queries stay in touch through the following channels',
  ContactSubTitle: 'We\'d love to hear from you',
  SendAMessage: 'Send a message',
  OurChannels: 'Our channels',
  AlreadyVotedToday: 'You already voted today, come back tomorrow',
  MyProjects: 'My projects',
  MonthlyVisits: 'Monthly visits',
  TotalVisitsAMonth: 'Total visits a month',
  NoRouteAccess: 'Invalid permissions to access this page',
  ProjectIsHidden: 'Project is hidden',
  TermsOfServiceContent: `<p><strong>Please not that these Terms of Service are subject to updates and/or changes at any time.</strong><br/>
  By using Devidex.com you are agreeing to the following terms of service.<br/>
  Please take a moment to review these rules detailed below. If you agree with them you may add your site or server to this Minecraft server list. <br/> 
  All Minecraft servers listed on this list are submitted for free by users registered on https://Devidex.com. Visiting these websites and/or servers is your own choice of action by clicking the link to those websites and/or servers, at your own risk.<br/>
  Our company does not take any liability for anything a user visits, however, any content you find that does not belong on https://Devidex.com should be reported and will be removed as soon as possible.</p>
   
  <p><strong>1. Acceptance of Terms of Use and Amendments</strong><br/> 
  Each time you use or access to https://Devidex.com or any sub domains, you agree to be bound by these Terms of Use, and as amended from time to time with or without notification.<br/>
  In addition, if you are using a particular service on https://Devidex.com, you will be subject to any rules or guidelines applicable to those services and they shall be incorporated by reference into these Terms of Use.</p>
   
  <p><strong>2. Our Service</strong><br/> 
  You agree that the owners of https://Devidex.com, exclusively reserve the right and may, at any time and without notice and any liability to you, modify or discontinue https://Devidex.com and its services or delete the data you provide, whether temporarily or permanently.<br/> 
  We shall have no responsibility or liability for the timeliness, deletion, failure to store, inaccuracy, or improper delivery of any data or information.</p>
  
  <p><strong>3. Sponsored slots</strong><br/>
  Sponsored, or highlighted slots are slots that are listed on the top of the homepage of https://Devidex.com. To be listed in a sponsored slot you will have to pay a fee for a set amount of time.<br/>
  We reserve the right to change the method by which sponsored slots are allocated from month to month and also the quantity and nature of the slots.<br/>
  By buying a sponsored slot you will pay the full amount before your server is placed in a sponsored slot.<br/>
  <!--By placing a bid during an auction you are agreeing that if your bid is in the top slots for the auction when the auction closes (a winning bid) you will pay the full amount before the payment due date. 
  Payments can be made using PayPal 
  You may also be obliged to pay if another server fails to do so (or is deleted) and your bid moves into the top auction bids. 
  If this situation arises payment is expected within 24 hours of us giving notice. Failure to pay will result in the permanent removal of your server (and any other servers you own) from the website.
  We reserve the right to remove bids at any point during and after the auction provided payment has not been made. -->
  Sponsored server slots are non-refundable and are provided 'as-is' with no guarantee of uptime or site traffic included.<br/>
  When, after purchasing a sponsored slot, payment is cancelled from the users side the server will be deleted from https://Devidex.com together with any other Minecraft servers its register has registered on https://Devidex.com.<br/>
  Additional to the Minecraft servers being removed from https://Devidex.com the registers account will also be removed and he is not allowed to uses our service in the future.</p>
  
  <p><strong>4. Voting and Ranking System</strong><br/> 
  Listed Minecraft servers are ranked by the number of unique votes received on the homepage and 'server' page when no filter is used.<br/>
  You agree to not use any kind of automated voting system, proxy servers, vpn, scripts or any other mechanism or application that could lead to an unfair (cheated) higher amount of votes for your listed Minecraft server.<br/>
  In case you are caught cheating the voting system to gain advantage, in any possible way, we reserve the right to reset your votes and disable your listing to prevent visitors from visiting your page on https://Devidex.com, your URL address or server IP permanently.<br/>
  If there are any outstanding purchases/credit on your account you will not be able to claim/refund this as you have void the terms and conditions.<br/>
  Votes are reset on each first day of the month. This way newly registered servers are also able to rank higher.</p>
  
  <p><strong>5. Your Responsibilities and Registration Obligations</strong><br/> 
  In order to use https://Devidex.com, you must register on our site, and agree to provide truthful information when requested. When registering, you explicitly agree to our Terms of Use and as may be modified by us from time to time.</p>
  
  <p><strong>6. Registration and Password</strong><br/> 
  You are responsible to maintain the confidentiality of your password and shall be responsible for all uses via your registration and/or login, whether authorized or unauthorized by you.<br/>
  You agree to immediately notify us of any unauthorized use or your registration, user account or password.</p>
  
  <p><strong>7. Your Conduct</strong><br/> 
  You agree that all information or data of any kind, whether text, software, code, music or sound, photographs or graphics, video or other materials (content), publicly or privately provided, shall be the sole responsibility of the person providing the content or the person whose user account is used. 
  You agree that our website may expose you to content that may be objectionable or offensive. We shall not be responsible to you in any way for the content that appears on https://Devidex.com nor for any error or omission.<br/>
  You explicitly agree, in using https://Devidex.com or any services provided, that you shall not: 
  <ul>
  <li>(a) Provide any content or perform any conduct that may be threatening, harmful, abusive, harassing, stalking, tortuous, defamatory, libellous, vulgar, obscene, offensive, objectionable, pornographic, designed to or does interfere or interrupt https://Devidex.com or any service provided, infected with a virus or other destructive or deleterious programming routine, give rise to civil or criminal liability, or which may violate an applicable local, national or international law;</li> 
  <li>(b) Impersonate or misrepresent your association with any person or entity, or forge or otherwise seek to conceal or misrepresent the origin of any content provided by you;</li> 
  <li>(c) Collect or harvest any data about other users;</li>
  <li>(d) Provide or use https://Devidex.com and any content or service in any commercial manner or in any manner that would involve junk mail, spam, chain letters, pyramid schemes, or any other form of unauthorized advertising without our prior written consent.</li>
  </ul></p>
  
  <p><strong>8. Removal of Servers</strong><br/>
  Devidex.com reserves the right to remove any server at any time with or without prior notice.<br/>
  A server may be removed for a number of reasons including, but not limited to:
  <ul><li>Inappropriate server details (banner, name, description etc…)</li>
  <li>Abuse of the voting system</li>
  <li>Low uptime <!--(servers older than 10 days are automatically removed --></li>
  <li>Anything other than the exact server name in the name field</li></ul>
  Cracked servers of any kind are strictly forbidden and should not be added to the website.<br/>
  Any cracked servers discovered will be immediately removed.<br/>
  In addition, we reserve the right to modify or remove individual server details (banner, name, description etc…) without prior notice when they are deemed inappropriate but not to the extent that warrants a complete removal of the server.</p>
  
  <p><strong>9. Submission of content on Devidex.com</strong><br/> 
  By providing any content to https://Devidex.com: 
  <ul><li>(a) you agree to grant to us a worldwide, royalty-free, perpetual, non-exclusive right and license (including any moral rights or other necessary rights) to use, display, reproduce, modify, adapt, publish, distribute, perform, promote, archive, translate, and to create derivative works and compilations, in whole or in part. 
  Such license will apply with respect to any form, media, technology known or later developed;</li>
  <li>(b) you warrant and represent that you have all legal, moral, and other rights that may be necessary to grant us with the license set forth in this Section 7; You acknowledge and agree that we shall have the right (but not obligation), in our sole discretion, to refuse to publish or to remove or block access to any content you provide at any time and for any reason, with or without notice.</li></ul></p>
  
  <p><strong>10. Third Party Services</strong><br/> 
  Goods and services of third parties may be advertised and/or made available on or through https://Devidex.com. Representations made regarding products and services provided by third parties are governed by the policies and representations made by these third parties.<br/>
  We shall not be liable for or responsible in any manner for any of your dealings or interaction with third parties.</p>
  
  <p><strong>11. Indemnification</strong><br/> 
  You agree to indemnify and hold us harmless, our subsidiaries, affiliates, related parties, officers, directors, employees, agents, independent contractors, advertisers, partners, and co-branders from any claim or demand, including reasonable attorneys fees, that may be made by any third party, that is due to or arising out of your conduct or connection with https://Devidex.com or service, your provision of content, your violation of this Terms of Use or any other violation of the rights of another person or party.</p>
  
  <p><strong>12. Disclaimer of Warranties</strong><br/> 
  you understand and agree that your use of Devidex.com and any other service or content provided (the service) is made available and provided to you at your own risk.<br/>
  It is provided to you as is and we expressly disclaim all warranties of any kind, implied or express, including but not limited to the warranties of merchantability, fitness for a particular purpose, and non-infringement.<br/>
  We make no warranty, implied or xpress, that any part of the service will be uninterrupted, error-free, virus-free, timely, secure, accurate, reliable, of any quality, nor that any content is safe in any manner for download.<br/>
  You understand and agree that neither us nor any participant in the service provides professional advice of any kind and that use of such adivce or any other information is soley at your own risk and without our liability of any kind.<br/>
  Some jurisdictions may not allow disclaimers of implied warranties and the above disclaimer may not apply to you only as it relates to implied warranties.</p>
  
  <p><strong>13. Limitation of Liability</strong><br/> 
  You expressly understand and agree that we shall not be liable for any direct, indirect, special, incidental, consequential or exemplary damages, including but not limited to, damages for loss or profits, goodwill, use, data or other intangible loss (even if we have been adviced of the possibility of such damages), resulting from or arising out of (I) the use of or the inability to use the service, (II) the cost to obtain substitute goods and/or services resulting from any transaction entered into or through the service,(III) unauthorized access to or alteration of your data transmissions, (IV) statements or conduct of any third party on the service, or (V) any other matter relating to the service.<br/>
  Note that by accepting the terms in the document, you are also waiving your right, to take any action, legal or otherwise, against anyone or anything related to Devidex.com.<br/>
  Please remember that we are not responsible for any messages posted. We do not vouch for or warrant the accuracy, completeness or usefulness of any message, and are not responsible for the contents of any message.<br/>
  The messages express the views of the author of the message, not necessarily the views of this bulletin board. Any user who feels that a posted message is objectionable is encouraged to contact us immediately by email.<br/>
  We have the ability to remove objectionable messages and we will make every effort to do so, within a reasonable time frame, if we determine that removal is necessary.<br/>
  You agree, through your use of this service, that you will not use this bulletin board to post any material which is knowingly false and/or defamatory, inaccurate, abusive, vulgar, hateful, harassing, obscene, profane, sexually oriented, threatening, invasive of a persons privacy, or otherwise violative of any law.<br/>
  You agree not to post any copyrighted material unless the copyright is owned by you.</p>
  <br/><br/>
  <P><strong>Last edited on 15-02-2017</strong></p></div></div>`,
  PrivacyPolicyContent: `<div>
  <p>
  We take privacy very seriously and we would never disclose your personal information to third parties or use it for bulk mail lists or newsletters.<br/>
  You should however be aware that we record data about your visit to our website for statistical purposes.<br/> 
  We only collect that to analyze our audience and we may provide this analysis to our potential advertisers and business partners.<br/>
  We may collect non-personal identification information about Users whenever they interact with our Site.<br/>
  Non-personal identification information may include the browser name, the type of computer and technical information about users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
  </p>
  
  <p><strong>1. What information do we collect?</strong><br/>
  We collect information from you when you register on https://Devidex.com, place an order and/or when you vote for a Minecraft server on this web site.
  When registering on https://Devidex.com, as appropriate, you will be asked to enter your: e-mail address. You may, however, visit https://Devidex.com anonymously. 
  When voting for a Minecraft server on https://Devidex.com we may collect your entered Minecraft username (for Votifer purposes) and IP address to prevent double-voting and also to validate your vote.</p>
  <p><strong>2. What do we use your information for?</strong><br/>
  Any of the information we collect from you may be used in one of the following ways: 
  <ul>
  <li>To personalize your experience (your information helps us to better respond to your individual needs).</li>
  <li>To improve Devidex.com (we continually strive to improve https://Devidex.com offerings based on the information and feedback we receive from you).</li>
  <li>To prevent booting or any kind of ways to cheat the voting system.</li>
  <li>To improve our customer service (your information helps us to more effectively respond to your customer service requests and support needs).</li>
  <li>To process transactions (such as payments for additional services).</li>
  <li>At the point when an end-user wishes to make a payment, his registration details may be passed to PayPal that provides payment processing services, in order for PayPal to better protect service and end-users from unauthorized payments</li>
  <li>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested.• To administer a contest, promotion, survey or other site feature.</li>
  <li>To send periodic emails (the email address you provide for order processing, will only be used to send you information and updates pertaining to your order).</li>
  </ul></p>
  
  <p><strong>3. How do we protect your information?</strong><br/>
  We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.<br/>
  We take reasonable steps to secure your personally identifiable information against unauthorized access or disclosure.<br/>
  Information we collect may be stored or processed on computers located in any country where we do business.<br/>
  Special situations may require disclosure of your data.</p>
  
  <p><strong>4. Cookies</strong><br/>
  Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information. 
  We use cookies only to save your login preferences for future visits and to prevent booting and voting cheats.<br/>
  If you wish to disable cookies, you may do so through your individual browser options.<br/>
  Third party vendors, including Google, use cookies to serve ads based on a user's prior visits to your website and/or Minecraft server.<br/>
  Google's use of the DoubleClick cookie enables it and its partners to serve ads to your users based on their visit to your sites and/or other sites on the Internet.<br/>
  We at https://Devidex.com use tools such as (but not limited too) Google Analytics, Google Webmasters and Google Adsense to help improve the experience and maintain our service.<br/>
  Users may opt out of the use of the DoubleClick cookie for interest-based advertising by visiting Ads Settings.</p>
  
  <p><strong>5. Third Party Links</strong><br/>
  Servers listed on https://Devidex.com are able to add an url to their website and occasionally, at our discretion, we may include or offer third party products or services on https://Devidex.com.<br/> 
  These third party sites (including the sites of servers listed on this site) have separate and independent privacy policies.<br/>
  We therefore have no responsibility or liability for the content and activities of these linked sites.<br/>
  Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
  
  <p><strong>6. Accepting the Privacy Policy</strong><br/>
  By using and accessing https://Devidex.com, you consent to our privacy policy.
  If you do not agree to this policy, please do not use our Site. 
  Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
  
  <p>Our privacy policy may change from time to time. If we make any material changes to our policies, we will place a prominent notice on our communication channels. 
  If the change materially affects registered users, we will send a notice to you by email, notification or text. 
  If you have any questions or concerns about our privacy policies, please <a href="/contact">Contact Us</a>
  <br/><br/>
  <strong>This page was last edited on xx-xx-20XX</strong></p></div>`
};
