import React, { ChangeEvent, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './EnterRoomForm.module.scss';

export default function EnterRoomForm(): React.ReactElement {
  const { t } = useTranslation();

  const navigate = useNavigate();

  function onSubmit(): void {
    navigate(`/room/${formInfo.room}/username/${formInfo.username}`);
  }

  const INPUT_USERNAME = 'username';
  const INPUT_ROOM_NAME = 'room';

  interface IRegisterFormData {
    username: string,
    room: string,
  }

  const defaultFormData: IRegisterFormData = {
    username: '',
    room: '',
  };

  const [formInfo, setFormInfo] = useState<IRegisterFormData>(defaultFormData);

  const updateFormInfo = <P extends keyof IRegisterFormData>(prop: P, value: IRegisterFormData[P]) => {
    setFormInfo({ ...formInfo, [prop]: value });
  };

  function handleFormChange(event: ChangeEvent): void {
    const { name, value } = (event.target as HTMLInputElement);
    switch (name) {
      case INPUT_USERNAME:
        updateFormInfo(INPUT_USERNAME, value);
        break;
      case INPUT_ROOM_NAME:
        updateFormInfo(INPUT_ROOM_NAME, value);
    }
  }

  return (
    <div className={styles.roomFormContainer}>
      <div className={styles.content}>
        <Form onSubmit={onSubmit}>
          <Form.Label>{t('InboxName')}</Form.Label>
          <Form.Control
            name={INPUT_ROOM_NAME}
            placeholder={t('InboxName')}
            type='text'
            required
            onChange={handleFormChange}
          />

          <Form.Label>{t('Username')}</Form.Label>
          <Form.Control
            name={INPUT_USERNAME}
            placeholder={t('Username')}
            type='text'
            required
            onChange={handleFormChange}
          />
          <div className={styles.useSteps}>
            <ul>
              <li>{`${t('SendAnEmailTo')} (*.)${formInfo.room ? formInfo.room : t('YourRoomName')}@MailReel.com`}</li>
            </ul>
          </div>
          <Button type='submit'>{t('OpenInbox')}</Button>
        </Form>
      </div>
    </div>
  );
}
