import React from 'react';
import { MailInfo } from '../../types/mail.types';

interface EmailListItemProps {
  email: MailInfo;
  onDelete: (mailId: string) => void;
  onDownload: (mailId: string) => void;
  openEmail: (mailId: string) => void;
}

export default function EmailListItem({ email, openEmail }: EmailListItemProps): React.ReactElement {
  return (
    <tr onClick={(e) => {
      e.preventDefault();
      openEmail(email.id);
    }}>
      <td>{email.from[0].address}</td>
      <td>{email.to[0].address}</td>
      <td>{email.subject}</td>
      <td>{email.received}</td>
    </tr>
  );
}
