import React, { FormEvent } from 'react';
import styles from './Header.module.scss';
import { useTranslation } from 'react-i18next';

export default function Header(): React.ReactElement {
  const { i18n } = useTranslation();
  const { t } = useTranslation();

  function changeLanguageHandler(event: FormEvent): void {
    const target = event.target as HTMLInputElement;
    const languageValue = target.value;
    void i18n.changeLanguage(languageValue);
  }

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerContent}>
        <div className={styles.title}>{t('AppTitle')}</div>
        <select className={styles.languageSelect} style={{ width: 200 }} onChange={changeLanguageHandler}>
          <option value='en'>English</option>
          <option value='nl'>Dutch</option>
        </select>
      </div>
    </div>
  );
};

