import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/home/Home';
import ReelRoomPage from '../pages/reel-room/ReelRoom';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage/>}/>
        <Route path={'room/:room/username/:username'} element={<ReelRoomPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}
