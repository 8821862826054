import React, { PropsWithChildren } from 'react';
import Header from '../../components/Header/Header';
import styles from './base.module.scss';

interface BaseLayoutProps extends PropsWithChildren { }

export default function BaseLayout({ children }: BaseLayoutProps): React.ReactElement {
  return (
    <div className={styles.baseBody}>
      <Header />
      <div className={styles.fullPage}>
        <div className={styles.contentContainer}>
          {children}
        </div>
      </div>
    </div>
  );
}
