import React, { PropsWithChildren } from 'react';
import styles from './PageRow.module.scss';

interface PageRowProps extends PropsWithChildren { }

export default function PageRow({ children }: PageRowProps): React.ReactElement {
  return (
    <div className={styles.pageRow}>
      {children}
    </div>
  );
}
