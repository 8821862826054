import React, { useEffect, useState } from 'react';
import styles from './Home.module.scss';
import BaseLayout from '../../layout/base/base.layout';
import PageRow from '../../layout/page-row/PageRow.layout';
import EnterRoomForm from '../../components/enter-reel-room-form/EnterRoomForm';
import { useTranslation } from 'react-i18next';
import { SiteInfo } from '../../types/site.types';
import Api from '../../api/api';

export default function HomePage(): React.ReactElement {
  const { t } = useTranslation();

  const [data, setData] = useState<SiteInfo>({ count: 0 });

  useEffect(() => {
    void Api.getSiteInfo().then(res => {
      setData(res);
    });
  }, []);

  return (
    <BaseLayout>
      <div className={styles.homeContainer}>
        <PageRow>
          <div className={styles.entryRow}>
            <div className={styles.pageTitle}>
              <div className={styles.appTitle}>{t('AppTitle')}</div>
              <div className={styles.subTitle}>{t('SiteTagLine')}</div>
              <div>
                <cn-card>
                  <cn-card-title>
                    {t('Receive emails for testing')}
                  </cn-card-title>
                  <cn-card-body>
                    <div dangerouslySetInnerHTML={{ __html: t('AppDescriptionHome') }}/>
                  </cn-card-body>
                </cn-card>
              </div>
            </div>
            <div className={styles.form}>
              <EnterRoomForm/>
            </div>
          </div>
        </PageRow>
        <PageRow>
          <div className={styles.counter}>
            <cn-card>
              <cn-card-body>
                <div className={styles.count}>{data.count}</div>
                <div className={styles.description}>{t('EmailsImported')}</div>
              </cn-card-body>
            </cn-card>
          </div>
        </PageRow>
        <PageRow>
          <div className={styles.infoRow}>
            <cn-card>
              <cn-card-body>
                <cn-card-title center main-title={t('StepCreateInbox')}/>
                {t('StepCreateInboxDescription')}
              </cn-card-body>
            </cn-card>

            <cn-card>
              <cn-card-body>
                <cn-card-title center main-title={t('StepSendEmail')}/>
                {t('StepSendEmailDescription')}
              </cn-card-body>
            </cn-card>

            <cn-card>
              <cn-card-body>
                <cn-card-title center main-title={t('StepReceiveEmails')}/>
                {t('StepReceiveEmailsDescription')}
              </cn-card-body>
            </cn-card>
          </div>
        </PageRow>
      </div>
    </BaseLayout>
  );
}
