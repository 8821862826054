import React from 'react';
import styles from './InfoSet.module.scss';

interface InfoSetProps {
    title: string,
    value: string | undefined | null,
}

export default function InfoSet({ title, value }: InfoSetProps): React.ReactElement {
  return (
    <div className={styles.mailInfoContainer}>
      <div className={styles.infoTitle}>{title}:</div>
      <div className={styles.data}>{value}</div>
    </div>
  );
}