import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/Index.module.scss';
import reportWebVitals from './reportWebVitals';
import Router from './router/Router';
import './i18n';
import { ToastContainer } from 'react-toastify';
import { defineCustomElements, applyPolyfills } from 'componetic/loader';
import { JSX as LocalJSX } from 'componetic/dist/types/components';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// required for componetic code:
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

type StencilProps<T> = { [P in keyof T]: Omit<T[P], 'ref'> };

type ReactProps<T> = {
  [P in keyof T]: DetailedHTMLProps<HTMLAttributes<T[P]>, T[P]>
};

type StencilToReact<T = LocalJSX.IntrinsicElements,
  U = HTMLElementTagNameMap> = StencilProps<T> & ReactProps<U>;

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  export namespace JSX {
    interface IntrinsicElements extends StencilToReact {
    }
  }
}
void applyPolyfills().then(() => {
  defineCustomElements(window);
});

function RootApp(): React.ReactElement {
  return (
    <>
      <React.StrictMode>
        <Router />
        <ToastContainer autoClose={7000} />
      </React.StrictMode>
    </>
  );
}

root.render(
  <RootApp/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
