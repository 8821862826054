import React from 'react';
import styles from './EmailDetails.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { EmailAddress, MailMessage } from '../../types/mail.types';
import InfoSet from './InfoSet/InfoSet';

interface EmailDetailsProps {
  onDelete: (mailId: string) => void;
  onDownload: (mailId: string) => void;
  email: MailMessage;
}

export default function EmailDetails({ onDelete, onDownload, email }: EmailDetailsProps): React.ReactElement {
  const { t } = useTranslation();

  function mapAddresses(emails: EmailAddress[]): string {
    return emails.map(email => `${email?.address}${email.name ? ` ${email.name}` : ''}`).join(', ');
  }

  return (
    <div className={styles.emailDetailsContainer}>
      <div className={styles.mailInfo}>
        <div className={styles.info}>
          <InfoSet title={t('From')} value={email.from ? mapAddresses(email.from) : ''}/>
          <InfoSet title={t('To')} value={mapAddresses(email.to)}/>
          <InfoSet title={t('Subject')} value={email.subject}/>
          <InfoSet title={t('Received')} value={email.received}/>
        </div>
        <div className={styles.buttons}>
          <Button onClick={() => onDelete(email.id)} size={'sm'}>Delete</Button>
          <Button onClick={() => onDownload(email.id)} size={'sm'}>Download</Button>
        </div>
      </div>

      <div className={styles.view}>
        <div dangerouslySetInnerHTML={{
          __html: email.message.html || ''
        }}/>
      </div>
    </div>
  );
}
